<template>
  <b-nav-item-dropdown id="dropdown-grouped" variant="link" class="dropdown-language" right>
    <template #button-content>
      <b-img :src="currentLocale.img" height="14px" width="22px" :alt="currentLocale.locale" />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item v-for="localeObj in locales" :key="localeObj.locale" @click="changeLanguage(localeObj)">
      <b-img :src="localeObj.img" height="14px" width="22px" :alt="localeObj.locale" />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue';

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  created() {
    this.$i18n.locale = this.localeLanguage.locale ?? 'en'
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    localeLanguage: {
      get() {
        return this.$store.getters["getLanguage"];
      },
      set(newValue) {
        return this.$store.dispatch("setLanguage", newValue);
      },
    },
    currentLocale() {
      //console.log(this.locales.find((l) => l.locale === this.$i18n.locale))
      //this.localeLanguage = this.locales.find((l) => l.locale === this.$i18n.locale)
      // return this.locales.find((l) => l.locale === this.$i18n.locale);
      return this.localeLanguage;
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
      // {
      //   locale: 'fr',
      //   img: require('@/assets/images/flags/fr.png'),
      //   name: 'French',
      // },
      // {
      //   locale: 'de',
      //   img: require('@/assets/images/flags/de.png'),
      //   name: 'German',
      // },
      // {
      //   locale: 'pt',
      //   img: require('@/assets/images/flags/pt.png'),
      //   name: 'Portuguese',
      // },
    ];
    /* eslint-disable global-require */

    return {
      locales,
    };
  },
  methods: {
    changeLanguage(localeObj) {
      this.$i18n.locale = localeObj.locale
      this.localeLanguage = localeObj
      window.location.reload()
    }
  },
  watch:{
    // localeLanguage: {
    //   handler: function(val, before) {
    //     console.log(val)
    //   },
    // },
  }
};
</script>

<style></style>
