<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <feather-icon icon="ArrowLeftIcon" size="28" @click="backRouter()" class="mr-2 text-white cursor-pointer" />
      <feather-icon icon="ArrowRightIcon" size="28" @click="nextRouter()" class="text-white cursor-pointer" />
    </div> -->

    <b-navbar-nav class="nav d-flex align-items-center" style="width: 100%;">

      <b-col cols="7" class="d-flex justify-content-end align-items-center">
        
      <b-img :src="appLogoImage" width="220px" style="height: 100%;" alt="TME"  />
    </b-col>

      <b-col cols="5" class="d-flex justify-content-end align-items-center">


        <!-- language module buttons eng try etc. -->
      <Locale />

      <!-- change layout background color button -->
    <!-- <dark-Toggler class="d-none d-lg-block" /> -->

    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0">
            {{ userData.name }} {{ userData.surname }}
          </p>
          <span class="user-status" v-if="userData.role == 'supermanager'"
            >Supermanager</span
          >
          <span class="user-status" v-else>{{ userData.role }} </span>
        </div>

        <b-avatar
          size="40"
          variant="light-primary"
          badge
          :src="profileURL"
          :text="userData ? userData.name[0] + userData.name[1] : ''"
          class="badge-minimal"
          badge-variant="success"
        />
        <!-- <b-avatar size="40" variant="light-primary" badge src="" class="badge-minimal" badge-variant="success" />  -->
      </template>

      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="navigateProfile"
      >
        <feather-icon size="16" icon="UserIcon" class="mr-50" />
        <span>{{ $t('profile') }}</span>
      </b-dropdown-item>

      <b-dropdown-item href="https://document.sfc.tmss-repo.com/" target="_blank" link-class="d-flex align-items-center">
        <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
        <span>{{ $t('help') }}</span>
      </b-dropdown-item>

      <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
        <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
        <span>
          <b-link class="alert-link mt-5 mb-5">
            {{ $t('logout') }}
          </b-link></span
        >
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </b-col>
      
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "./DarkToggler.vue";
import Locale from "./Locale.vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import router from "@/router";
import store from "@/store";
import axiosIns from "@/libs/axios";
import moment from "moment";
import { mapActions } from "vuex";

export default {
 
  data() {

    var appLogoImage = require('@/assets/images/logo/logo.png');
    return {
      appLogoImage,
      baseURL: store.state.app.baseURL,
      email: localStorage.getItem("email"),
      userData: JSON.parse(localStorage.getItem("userData")),
      // profileURL:
      //   store.state.app.profileURL +
      //   JSON.parse(localStorage.getItem("userData")).profile,
      profileURL:null,
      mainProps: {
        blank: true,
        blankColor: "#FFF",
        height: 70,

        class: "m1",
        block: true,
      },
    };
  },

  created() {
    //this.userDetails();
  },

  mounted() {
  },

  beforeDestroy() {
    localStorage.removeItem('vuex');
  },

  methods: {
    ...mapActions(["setUser","setValidPermission"]),
    backRouter() {
      router.go(-1);
    },

    nextRouter() {
      router.go(1);
    },

    navigateProfile() {
      if (router.currentRoute.name != "user-profile") {
        this.$router.push({ name: "user-profile" });
      }
    },

    userDetails() {
      axiosIns
        .get(`user/${this.userData.id}`)
        .then((res) => {
          this.userData = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    checkStatusAndLogOutTime() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`user/${this.userData.id}`)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");
      localStorage.removeItem("userPerms");
      localStorage.removeItem("vuex");
      localStorage.clear();

      //reset user state information
      this.setUser({name:null,password:null,role:null})
      this.setValidPermission(null)

      // Reset ability
      /*
      this.$ability.update(initialAbility);
      */

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
  },

  watch: {
    // $route(to, from) {
    //   this.checkStatusAndLogOutTime().then((user) => {
    //     if (user.status != "A") {
    //       this.logout();
    //     }

    //     if (user.autoLogoutTime != undefined || user.autoLogoutTime != null) {
    //       const dateNow = moment(new Date()).format("YYYY-MM-DD hh:mm:s");

    //       if (dateNow > user.autoLogoutTime) {
    //         this.logout();
    //       }
    //     } else {
    //       this.logout();
    //     }
    //   });
    // },
  },

  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    permission: {
      get() {
        return this.$store.getters["validPermissions"];
      },
      set(newValue) {
        return this.$store.dispatch("setValidPermission", newValue);
      },
    }
  },

  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
    Locale
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
