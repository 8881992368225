export default [
  {
    title: 'Home',
    route: 'dashboard',
    icon: 'HomeIcon',
    Permission: '',
  },

  // {
  //   header: 'Users',
  //   icon: 'UsersIcon',
  //   Permission: 'see_user_menu',
  //   children: [
  //     {
  //       title: 'Add',
  //       route: 'user-add',
  //       icon: 'UserPlusIcon',
  //     },
  //     {
  //       title: 'List',
  //       route: 'user-list',
  //       icon: 'ListIcon',
  //     },
  //     {
  //       title: 'Edit',
  //       route: 'user-edit',
  //       icon: 'EditIcon',
  //       disabled: true,
  //     },
  //   ],
  // },

  // {
  //   title: 'Settings',
  //   route: 'settings',
  //   icon: 'SettingsIcon',
  //   Permission: 'see_settings_menu',
  // },
  {
    title: 'Equity',
    route: 'equities',
    icon: 'TrendingUpIcon',
    Permission: 'equity_tab',
  },
  {
    title: 'Bond',
    route: 'bonds',
    icon: 'PieChartIcon',
    Permission: 'equity_tab',
  },
  

  // {
  //   title: 'Equity & Bond',
  //   route: 'equity-bond',
  //   icon: 'BarChart2Icon',
  //   Permission: 'equity_tab',
  // },
  {
    title: 'FX Spot-Forward-Swap',
    route: 'fxsfs',
    icon: 'RepeatIcon',
    Permission: 'fx_tab',
  },

  // {
  //   title: 'FX Option',
  //   route: 'fx-options',
  //   icon: 'AlignRightIcon',
  //   Permission: 'fx_tab',
  // },

  {
    title: 'Transfers',
    route: 'transfers',
    icon: 'SendIcon',
    Permission: 'transfers_tab',
  },

  {
    title: 'Account Creation',
    route: 'account-creation',
    icon: 'UserIcon',
    Permission: 'account_tab',
  },

  {
    title: 'Security Creation',
    route: 'security-creation',
    icon: 'PlusSquareIcon',
    Permission: 'security_tab',
  },

  {
    title: 'Journals',
    route: 'journals',
    icon: 'MenuIcon',
    Permission: 'journals_tab',
  },
  {
    title: 'Reports',
    icon: 'FileTextIcon',
    Permission: 'see_report_menu',
    children: [
      {
        title: 'Portfolio',
        route: 'portfolios',
        icon: 'ListIcon',
        Permission: 'portfolios_tab',
      },
      {
        title: 'Statement',
        route: 'statements',
        icon: 'MinusCircleIcon',
        Permission: 'statements_tab',
      },
    ],
  },

  {
    title: 'Settings',
    route: 'settings',
    icon: 'SettingsIcon',
    Permission: 'settings_tab',
  },
  
];
