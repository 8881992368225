<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link class="ml-25" href="https://systemstailormade.com/" target="_blank">STM</b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <span class="float-md-right d-none d-md-block" 
      ><a href="https://systemstailormade.com/" target="_blank" style="color: #6e6b7b; box-shadow: 0 0 15px 0 rgb(255, 255, 255);"> <img src="https://systemstailormade.com/wp-content/uploads/2023/05/gtm-1-1200x277.png" height="35px" alt="logo"></a>
      <!-- <feather-icon icon="FileTextIcon" size="16" class="text-success stroke-current" /> -->
     
    </span>
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
};
</script>
